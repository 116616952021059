<template>
  <div>
    <validation-observer ref="formReference" v-slot="{ invalid }">
      <v-form id="form-reference" @submit.prevent="checkReference">
        <validation-provider
          v-slot="{ errors }"
          name="Name"
          rules="required"
        >
          <v-text-field
            label="Nama"
            placeholder="Nama"
            v-model="name"
            :error-messages="errors"
            clearable
            type="text"
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="Email"
          rules="required|email"
        >
          <v-text-field
            label="E-mail"
            placeholder="E-mail"
            v-model="email"
            :error-messages="errors"
            clearable
            type="text"
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="WA Phone"
          rules="required|phoneNumber"
        >
          <v-text-field
            label="Nomor WA"
            placeholder="Nomor WA"
            prefix="+62"
            v-model="phoneNumber"
            :error-messages="errors"
            clearable
            type="number"
          ></v-text-field>
        </validation-provider>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            class="mt-4"
            :min-width="160"
            type="submit"
            form="form-reference"
            large
            :disabled="isDisabled || invalid"
          >
            Kirim
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfo" :info="info"/>
  </div>
</template>

<script>
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import { get } from 'dot-prop'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'

  // Constants
  import {
    API_REGISTER
  } from '@/constants/apis'

  // Components
  import DialogInfo from '@/components/common/DialogInfo'

  export default {
    name: 'FormReference',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogInfo
    },
    data () {
      return {
        name: '',
        email: '',
        phoneNumber: '',
        isError: false,
        isShowAllert: false
      }
    },
    computed: {
      isDisabled () {
        return !this.name || !this.email || !this.phoneNumber || this.isError
      }, 
      info () {
        return {
          title: 'home.reference.dialog.title',
          desc: 'home.reference.dialog.desc',
          btn: 'home.reference.dialog.btn'
        }
      }
    },
    mounted () {
      extend('required', required)
      extend('email', email)
      extend('phoneNumber', {
        validate: (value) => {
          const MOBILEREG = /^(^8)(\d{3,4}-?){2}\d{2,3}$/g
          if (MOBILEREG.test(value)) {
            return true
          }
          return 'WA Phone Number is not valid'
        }
      })
    },
    methods: {
      async checkReference() {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const param = {
          name: this.name,
          email: this.email,
          phone: `+62${this.phoneNumber}`,
          role: 'peminat'
        }
        const res = await request(API_REGISTER, param)
        if (res.success) {
          if (this.$refs.dialogInfo) {
            this.$refs.dialogInfo.show()
          }
          if (this.$refs.formReference){
            this.name = ''
            this.email = ''
            this.phoneNumber = ''
            this.$nextTick(() => {
              this.$refs.formReference.reset()
            })
          }
        } else {
          const message = get(res, 'resultInfo.resultMsg', '')
          this.$emit('handleAlert', true, message)
        }
        loadscreen.hide()
      }
    }
  }
</script>

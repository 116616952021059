var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"formReference",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"id":"form-reference"},on:{"submit":function($event){$event.preventDefault();return _vm.checkReference.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nama","placeholder":"Nama","error-messages":errors,"clearable":"","type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail","placeholder":"E-mail","error-messages":errors,"clearable":"","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"WA Phone","rules":"required|phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nomor WA","placeholder":"Nomor WA","prefix":"+62","error-messages":errors,"clearable":"","type":"number"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","min-width":160,"type":"submit","form":"form-reference","large":"","disabled":_vm.isDisabled || invalid}},[_vm._v(" Kirim ")])],1)],1)]}}])}),_c('dialog-info',{ref:"dialogInfo",attrs:{"info":_vm.info}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
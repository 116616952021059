<template>
  <div class="home-web">
    <div class="home-web__section1 d-flex flex-column justify-center align-center">
      <h1>Pendaftaran<br>Sekolah Tinggi Teologi Amanat Agung</h1>
      <v-btn 
        color="primary"
        large
        class="mt-6"
        :min-width="160"
        @click="goToLogin()"
      >
        Daftar
      </v-btn>
    </div>
    <v-container>
      <div class="main-container">
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            class="d-flex justify-center align-center"
            :class="{'pb-6': $vuetify.breakpoint.smAndDown}"
          >
            <div class="">
              <v-img
                lazy-src="@/assets/imgs/logo-chat.png"
                height="auto"
                width="144"
                src="@/assets/imgs/logo-chat.png"
              ></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="8"
            class="d-flex align-center"
          >
            <div class="reference-section">
              <h3>{{ 'home.reference.title' | text }}</h3>
              <p class="mt-2">{{ 'home.reference.desc' | text }}</p>
              <v-alert
                dismissible
                type="error"
                v-model="isShowAlert"
              >{{ alertMsg }}</v-alert>
              <form-reference @handleAlert="handleAlert"/>
            </div>
          </v-col>
        </v-row>
      </div>
      <dialog-info ref="dialogInfo" :info="info"/>
    </v-container>
  </div>
</template>

<script>
  import { get } from 'dot-prop'
  // Components
  import FormReference from '@/apps/web/components/forms/formReference'
  import DialogInfo from '@/components/common/DialogInfo'
  // Constants
  import { LOGIN } from '@/constants/pages'
  
  export default {
    name: 'HomeWeb',
    components: {
      FormReference,
      DialogInfo,
    },
    data() {
      return {
        isShowAlert: false,
        alertMsg: '',
        info: {
          title: 'dialog.recommend.error',
          desc: 'dialog.recommend.error.desc',
          btn: 'dialog.success.btn'
        }
      }
    },
    created () {
      const isErrorRecommend = get(this.$store.state, 'web.errorRecommend', false)
      if (isErrorRecommend) {
        setTimeout(()=> {
          this.$refs.dialogInfo && this.$refs.dialogInfo.show()
          this.$store.dispatch('web/setErrorRecommendation', false)
        }, 100)
      }
    },
    methods: {
      goToLogin () {
        this.$router.push({
          name: LOGIN
        }).catch(() => {})
      },
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      }
    }
      
  }
</script>

<style lang="scss" scoped>
.home-web {
  &__section1 {
    min-height: 450px;
    width: 100%;
    background-image: url("../../../assets/imgs/image-home.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow:inset 0 0 0 2000px rgba(67, 67, 67, 0.29);
    text-align: center;
    h1 {
      color: white;
    }
  }
  .reference-section {
    width: 100%;
  }
}
</style>